<template>
    <div>
        <header-portrate />

    <div class="m-2">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" style="margin-top:60px !important;"></tabs-comp>
        
        <v-row class="m-2">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="tablerows"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    width="100%"
                    id="MainTable"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td style="width:10%;" class="text-center">{{ row.item.itemcode }}</td>
                            <td style="width:60%;" class="text-start" nowrap>{{ row.item.name }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item.qty }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item.ftotal }}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <vue-snotify></vue-snotify>
    </div>

        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import Footer from '@/components/Footer.vue';
  import HeaderPortrate from '@/components/Header-Portrate.vue'
export default{
  components: { breadCrumbs, TabsComp,Footer,HeaderPortrate },
    name: 'Products',
    data() {
        return {
            sortBy: 'qty',
            sortDesc: true,
            active_tab:1,
            items: [
                // {text: this.$store.state.hometitle, disabled: false, to: '/home'},
                // {text: 'المخزون', disabled: false, to: '/inventory/products'},
                {text: 'الاصناف الأكثر مبيعا', disabled: true, to: '/inventory/best-seller'},
            ],
            
            headersd: [
                {
                    text: 'رقم القطعة',
                    align: 'center',
                    filterable: false,
                    value: 'itemcode',
                    sortable: false,
                },
                {
                    text: 'الاسم',
                    align: 'center',
                    filterable: false,
                    value: 'name',
                    sortable: false,
                },
                {
                    text: 'الكمية المباعة',
                    align: 'center',
                    filterable: false,
                    value: 'qty',
                    sortable: false,
                },
                {
                    text: 'أجمالي السعر',
                    align: 'center',
                    filterable: false,
                    value: 'ftotal',
                    sortable: false,
                },
            ],
            tablerows: [

            ],
            
        }
    },
    created() {
        this.getProducts();
    },
    methods: {
        calcTo () {
            const xy = this.$store.state.products;
            // this.tablerows = this.$store.state.products;
            for(let i=0;i<xy.length;i++){
                const ss = parseFloat(xy[i].price) * parseFloat(xy[i].qty);
                const y = {
                    itemcode: xy[i].itemcode,
                    name: xy[i].name,
                    qty: xy[i].qty,
                    price: xy[i].price,
                    ftotal: this.$RoundNum(ss)
                };
               this.tablerows.push(y);
            }
        },
        getProducts(){
          const post = new FormData();
          post.append('type','getProductBySale');
          post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
          post.append('auth',this.$cookies.get(this.$COOKIEPhase));
          post.append('data[order]','up');
            axios.post(this.$SAMCOTEC.r_path,post)
          .then((response) => {
              const res = response.data;
              // // console.log(res);
              if(res.results.data.items.length > 0){
                  const invo = res.results.data.invo;
                  
                  for(let i=0;i<res.results.data.items.length;i++){
                      const xy = res.results.data.items[i];
                      let qty = 0;
                      let price = 0;
                    
                      const y = {
                            itemcode: xy.itemcode,
                            name: xy.name,
                            qty: invo[xy.itemcode].qty,
                            price: xy.price,
                            ftotal: invo[xy.itemcode].total,
                      };

                    this.tablerows.push(y);
                  }
              }
          });
      },
      
    },
    computed: {
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
            if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
            }else{
              return this.$store.state.lang.ar;
            }
          }
          else if(this.$store.state.deflang == 'en'){
            return this.$store.state.lang.en;
          }else{
            return this.$store.state.lang.ar;
          }
      },
      headers: function() {
        return [
                {
                    text: this.lang.item_code,
                    align: 'center',
                    filterable: false,
                    value: 'itemcode',
                    sortable: false,
                },
                {
                    text: this.lang.item_name,
                    align: 'center',
                    filterable: false,
                    value: 'name',
                    sortable: false,
                },
                {
                    text: this.lang.sales_qtty,
                    align: 'center',
                    filterable: false,
                    value: 'qty',
                    sortable: false,
                },
                {
                    text: this.lang.price,
                    align: 'center',
                    filterable: false,
                    value: 'ftotal',
                    sortable: false,
                },
            ]
      },
      tabs: function() {
        const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
      }
    },
}
</script>